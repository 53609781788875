import {Fragment} from 'react';
import {MainPage} from './pages/main';
import {FaqPage} from './pages/faq';
import 'ui-kit-euroopt/dist/stylesheets/bundle.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {StateProviderFaq} from './store/faq/context';
import './assets/scss/global.scss';
import Chat from './components/chat';
import {ReviewAnswer} from "./pages/reviewAnswer";
import {ReviewThanksPage} from "./pages/reviewAnswer/thanksPage";
import {QuestionAnswer} from "./pages/questionAnswer";
import {QuestionThanksPage} from "./pages/questionAnswer/thanksPage";

function Wrapper() {
	return (
		<BrowserRouter>
			<Fragment>
				<Routes>
					<Route path="/" element={<MainPage />} />
					<Route
						path="/faq"
						element={
							<StateProviderFaq>
								<FaqPage />
							</StateProviderFaq>
						}
					/>
					<Route
						path="/review-answer"
						element={
							<ReviewAnswer />
						}
					/>
					<Route
						path="/review-answer/thanks-page"
						element={
							<ReviewThanksPage />
						}
					/>
					<Route
						path="/question-answer"
						element={
							<QuestionAnswer />
						}
					/>
					<Route
						path="/question-answer/thanks-page"
						element={
							<QuestionThanksPage />
						}
					/>
				</Routes>
				<Chat />
			</Fragment>
		</BrowserRouter>
	);
}

export default Wrapper;
